<template>
  <section>
    <CContainer>
      <CForm @submit.stop.prevent="Save()">
        <CCard>
          <CCardHeader class="d-flex justify-content-between align-items-center">
            <h5 class="mb-0">{{ Action === 'Edit' ? $t('Navigation.Post/EditCategory') : $t('Navigation.Post/AddCategory') }}</h5>
            <div class="card-header-actions d-flex">
              <WidgetsLocales v-if="$store.state.user.permission.MutipleLocale" :NowChooseLanguage.sync="NowChooseLanguage"></WidgetsLocales>
              <CButton v-if="!$store.state.user.permission.MutipleLocale" color="success" type="submit">
                {{ Action === 'Edit' ? $t('Global.Update') : $t('Global.Add') }}
              </CButton>
            </div>
          </CCardHeader>
          <CCardBody>
            <CTabs fade addTabsWrapperClasses="tab-info">
              <CTab :title="$t('Post/Category.Info')" active>
                <CRow>
                  <CCol md="8">
                    <CRow v-if="Action === 'Edit'" form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Global.URL') }}
                      </CCol>
                      <CCol sm="9" class="d-flex align-items-center">
                        <a v-if="Data.Slug" class="text-info mb-0 mr-2" :href="`https://${$store.state.user.permission.Domain}/posts/list/${Data.Slug}`" target="_blank">
                          https://{{ $store.state.user.permission.Domain }}/posts/list/{{ Data.Slug }}
                        </a>
                        <a v-if="Data.Slug" href="#" v-c-tooltip="{content: $t('Global.CopyURL'), placement: 'top'}" @click="CopyToClipboard(`https://${$store.state.user.permission.Domain}/posts/list/${Data.Slug}`)" class="text-dark">
                          <i class="far fa-copy" />
                        </a>
                      </CCol>
                    </CRow>
                    <CInput
                        :label="$t('Post/Category.Slug')"
                        v-model="Data.Slug"
                        horizontal
                    />
                    <template v-if="NowChooseLanguage === 'default'">
                      <CInput
                          :label="$t('Post/Category.Name')"
                          v-model="Data.Name"
                          horizontal
                      />
                      <CRow form class="form-group">
                        <CCol tag="label" sm="3" class="col-form-label">
                          {{ $t('Post/Category.Tags') }}
                        </CCol>
                        <CCol sm="9">
                          <multiselect
                              :key="NowChooseLanguage"
                              v-model="Data.Tags"
                              :tag-placeholder="$t('Global.EnterToAdd')"
                              :placeholder="$t('Global.PleaseKeyIn')"
                              :deselectLabel="$t('Global.DeselectLabel')"
                              :selectedLabel="$t('Global.SelectedLabel')"
                              :options="Data.Tags"
                              :multiple="true"
                              :taggable="true"
                              @tag="AddTag">
                            <template #noOptions>
                              {{ $t('Global.NoOptions') }}
                            </template>
                          </multiselect>
                        </CCol>
                      </CRow>
                      <CTextarea :label="$t('Post/Detail.Introduction')" v-model="Data.Introduction" rows="4" horizontal />
                    </template>
                    <template v-else>
                      <CInput
                          :label="$t('Post/Category.Name')"
                          v-model="Data.LanguageData[NowChooseLanguage].Name"
                          horizontal
                      />
                      <CRow form class="form-group">
                        <CCol tag="label" sm="3" class="col-form-label">
                          {{ $t('Post/Category.Tags') }}
                        </CCol>
                        <CCol sm="9">
                          <multiselect
                              :key="NowChooseLanguage"
                              v-model="Data.LanguageData[NowChooseLanguage].Tags"
                              :tag-placeholder="$t('Global.EnterToAdd')"
                              :placeholder="$t('Global.PleaseKeyIn')"
                              :deselectLabel="$t('Global.DeselectLabel')"
                              :selectedLabel="$t('Global.SelectedLabel')"
                              :options="Data.LanguageData[NowChooseLanguage].Tags"
                              :multiple="true"
                              :taggable="true"
                              @tag="AddTag">
                            <template #noOptions>
                              {{ $t('Global.NoOptions') }}
                            </template>
                          </multiselect>
                        </CCol>
                      </CRow>
                      <CTextarea :label="$t('Post/Detail.Introduction')" v-model="Data.LanguageData[NowChooseLanguage].Introduction" rows="4" horizontal />
                    </template>
                    <CRow form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Post/Category.RelateCategories') }}
                      </CCol>
                      <CCol sm="9">
                        <multiselect
                          v-model="SelectCategories"
                          :options="CategoryList"
                          :optionHeight="34"
                          label="label"
                          track-by="value"
                          :placeholder="$t('Global.PleaseSelect')"
                          :selectLabel="$t('Global.SelectLabel')"
                          :selectedLabel="$t('Global.SelectedLabel')"
                          :deselectLabel="$t('Global.DeselectLabel')"
                          :multiple="true"
                          @input="SynchronizeCategories()"
                        >
                          <template #noOptions>
                            {{ $t('Global.NoOptions') }}
                          </template>
                        </multiselect>
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol md="4">
                    <CInput
                        :label="$t('Post/Category.Sort')"
                        v-model="Data.Sort"
                        horizontal
                    />
                    <CRow form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Post/Category.Status') }}
                      </CCol>
                      <CCol sm="9">
                        <CSwitch color="success" :checked.sync="PostCategoryStatus"/>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CTab>
              <CTab :title="$t('Post/Category.Images')">
                <h5>
                  {{ $t('Post/Detail.Carousel') }}
                </h5>
                <hr>
                <template v-if="NowChooseLanguage === 'default'">
                  <Draggable
                      v-model="Data.Carousel"
                      class="row no-gutters mx-n1 mb-3"
                      tag="div"
                      v-bind="DragOptions"
                      @start="Drag = true"
                      @end="Drag = false"
                  >
                    <CCol v-for="(Data, Index) in Data.Carousel" :key="Index" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="position-relative CarouselItem">
                        <img v-lazy="Data.URL" class="img-fluid" />
                        <div class="CarouselTools">
                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Carousel', false, Index)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="secondary" size="sm" class="mx-1" @click="OpenMediaMeta('Carousel', Index)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="danger" size="sm" class="mx-1" @click="RemoveImage('Carousel', Index)">
                            <CIcon name="cil-trash" />
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                        <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                        <div class="CarouselTools">
                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Carousel')">
                            <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                  </Draggable>
                </template>
                <template v-else>
                  <Draggable
                      v-model="Data.LanguageData[NowChooseLanguage].Carousel"
                      class="row no-gutters mx-n1 mb-3"
                      tag="div"
                      v-bind="DragOptions"
                      @start="Drag = true"
                      @end="Drag = false"
                  >
                    <CCol v-for="(Data, Index) in Data.LanguageData[NowChooseLanguage].Carousel" :key="Index" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="position-relative CarouselItem">
                        <img v-lazy="Data.URL" class="img-fluid" />
                        <div class="CarouselTools">
                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Carousel', false, Index)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="secondary" size="sm" class="mx-1" @click="OpenMediaMeta('Carousel', Index)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="danger" size="sm" class="mx-1" @click="RemoveImage('Carousel', Index)">
                            <CIcon name="cil-trash" />
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                        <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                        <div class="CarouselTools">
                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Carousel')">
                            <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                  </Draggable>
                </template>
                <h5>{{ $t('Post/Category.Cover') }}</h5>
                <hr>
                <CRow class="no-gutters mx-n1 mb-3">
                  <template v-if="NowChooseLanguage === 'default'">
                    <CCol v-if="Data.Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="position-relative CarouselItem">
                        <img v-lazy="Data.Cover" class="img-fluid" />
                        <div class="CarouselTools">
                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol v-if="!Data.Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                        <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                        <div class="CarouselTools">
                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                            <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                  </template>
                  <template v-else>
                    <CCol v-if="Data.LanguageData[NowChooseLanguage].Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="position-relative CarouselItem">
                        <img v-lazy="Data.LanguageData[NowChooseLanguage].Cover" class="img-fluid" />
                        <div class="CarouselTools">
                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol v-if="!Data.LanguageData[NowChooseLanguage].Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                        <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                        <div class="CarouselTools">
                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                            <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                  </template>
                </CRow>
              </CTab>
              <CTab :title="$t('Post/Detail.Custom')">
                <CRow>
                  <CCol md="12">
                    <CDataTable
                        :items="VariableList"
                        :fields="VariableField"
                        :noItemsView="VariableNoItems"
                        responsive
                        hover
                    >
                      <template #Index="{item}">
                        <td>
                          <CInput v-model="item.Index" size="sm" type="text" />
                        </td>
                      </template>
                      <template #Value="{item}">
                        <td>
                          <CTextarea v-model="item.Value" size="sm" rows="1" />
                        </td>
                      </template>
                      <template #Type="{item}">
                        <td>
                          <CSelect :options="TypeList" v-model="item.Type" size="sm" :placeholder="$t('Global.PleaseSelect')" />
                        </td>
                      </template>
                      <template #Action="{index}">
                        <td>
                          <CButton color="danger" type="button" size="sm" @click="RemoveVariable(index)">
                            <CIcon name="cil-trash" class="c-icon-custom-size" />
                          </CButton>
                        </td>
                      </template>
                    </CDataTable>
                    <CButton color="info" type="button" size="sm" @click="AddVariable()">
                      {{ $t('Post/Detail.AddVariable') }}
                    </CButton>
                  </CCol>
                </CRow>
              </CTab>
              <CTab :title="$t('Post/Category.SEO')">
                <CRow>
                  <CCol md="8">
                    <template v-if="NowChooseLanguage === 'default'">
                      <CInput
                        :label="$t('Post/Category.SEO/Title')"
                        v-model="Data.SEO.Title"
                        horizontal
                      />
                      <CInput
                          :label="$t('Post/Category.SEO/Keyword')"
                          v-model="Data.SEO.Keywords"
                          horizontal
                      />
                      <CInput
                          :label="$t('Post/Category.SEO/Description')"
                          v-model="Data.SEO.Description"
                          horizontal
                      />
                      <CInput
                        :label="$t('Post/Category.SEO/OG:Title')"
                        v-model="Data.SEO.OGTitle"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Category.SEO/OG:Description')"
                        v-model="Data.SEO.OGDescription"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Category.SEO/OG:Image')"
                        v-model="Data.SEO.OGImage"
                        horizontal
                      />
                    </template>
                    <template v-else>
                      <CInput
                        :label="$t('Post/Detail.SEO/Title')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.Title"
                        horizontal
                      />
                      <CInput
                          :label="$t('Post/Category.SEO/Keyword')"
                          v-model="Data.LanguageData[NowChooseLanguage].SEO.Keywords"
                          horizontal
                      />
                      <CInput
                          :label="$t('Post/Category.SEO/Description')"
                          v-model="Data.LanguageData[NowChooseLanguage].SEO.Description"
                          horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/OG:Title')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.OGTitle"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/OG:Description')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.OGDescription"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/OG:Image')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.OGImage"
                        horizontal
                      />
                    </template>
                  </CCol>
                </CRow>
              </CTab>
            </CTabs>
          </CCardBody>
          <CCardFooter class="text-right">
            <CButton color="success" type="submit">
              {{ Action === 'Edit' ? $t('Global.Update') : $t('Global.Add') }}
            </CButton>
          </CCardFooter>
        </CCard>
        <CElementCover v-if="(Loading === true)" :opacity="0.75">
          <CSpinner color="success"/>
        </CElementCover>
      </CForm>
    </CContainer>
    <MediaStore :Config="MediaStoreConfig" @OK="SetImages(ChooseImageType)"></MediaStore>
    <MediaMeta :Config="MediaMetaConfig" @OK="SetMeta"></MediaMeta>
  </section>
</template>

<route>
{
  "meta": {
    "label": "文章分類編輯"
  }
}
</route>

<script>
export default {
  name: 'EditPostCategory',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect'),
    MediaStore: () => import('@/components/public/Media'),
    MediaMeta: () => import('@/components/public/MediaMeta'),
    WidgetsLocales: () => import('@/components/widgets/WidgetsLocales'),
    Draggable: () => import('vuedraggable')
  },
  data () {
    return {
      Action: 'Edit',
      Data: {
        Name: '',
        Slug: '',
        Tags: [],
        RelateCategories: [],
        Status: 0,
        Sort: 0,
        Cover: '',
        EnableResponseContent: 0,
        SEO: {
          Description: '',
          Keywords: ''
        },
        Carousel: [],
        LanguageData: {},
        ResponseData: {
          Mobile: {},
          Web: {}
        }
      }, //主資料

      OldSlug: '',
      CategoryList: [],
      SelectCategories: [], //格式化後的已選商品分類
      PostCategoryStatus: false, //格式化後的響應式商品內容開關
      EnableResponseContent: false, //格式化後的響應式商品內容開關
      VariableList: [],

      NowChooseDevice: 'Web',
      NowChooseLanguage: 'default',
      Loading: false,
      Drag: false,
      ChooseImageType: '',
      ChooseImageIndex: false,
      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false
      },
      MediaMetaConfig: {
        Display: false,
        ImageMeta: {}
      },

      TypeList: ['boolean', 'string', 'number', 'object', 'array'],
      VariableNoItems: {
        noResults: this.$t('Post/Detail.NoVariableItems'),
        noItems: this.$t('Post/Detail.NoVariableItems')
      }
    }
  },
  computed: {
    VariableField() {
      return [
        { key: 'Index', label: this.$t('Post/Detail.Index') },
        { key: 'Value', label: this.$t('Post/Detail.Value') },
        { key: 'Type', label: this.$t('Post/Detail.Type') },
        { key: 'Action', label: '' }
      ]
    },
    DragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  created() {
    this.Action = (this.$route.name === 'post-category-add' ? 'Add' : 'Edit')
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: 'error'
      })
    })
  },
  methods: {
    Init() {
      const Functions = [this.GetCategories()]
      if (this.Action === 'Edit') Functions.push(this.GetData())
      return Promise.all(Functions).then(() => {
        //多語系資料處理
        if (this.$store.state.user.permission.MutipleLocale && this.Action === 'Add') {
          this.$set(this.Data, 'LanguageData', {})
          this.$store.state.user.permission.Locales.forEach((locale) => {
            this.$set(this.Data.LanguageData, locale, {
              Name: '',
              Tags: [],
              SEO: {
                Description: '',
                Keywords: ''
              },
              Cover: '',
              Carousel: [],
              ResponseData: {
                Mobile: {},
                Web: {}
              }
            })
          })
        }
        this.NowChooseLanguage = this.$store.state.user.permission.MutipleLocale ? this.$store.state.user.permission.DefaultLocale : 'default'
        this.SelectCategories = this.CategoryList.filter((item) => {
          return this.Data.RelateCategories.includes(item.value)
        })
        return true
      }).catch((err) => {
        throw err
      })
    },
    Save() {
      let Action
      this.Data.Slug = this.Data.Slug.replace(/\//g, '_')
      switch (this.Action) {
        case 'Edit':
          Action = this.Edit()
          break
        case 'Add':
          Action = this.Add()
          break
        default:
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Error'),
            text: this.$t('Message.Post/SaveFail-1'),
            type: 'error'
          })
          return
      }
      this.Loading = true
      Action.then((result) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: result.msg,
          type: 'success'
        })
        if (this.Action === 'Add') {
          this.$router.replace('/post/category/detail/' + result.data.Slug)
        }
        if (this.Action === 'Edit' && this.OldSlug !== this.Data.Slug) {
          this.$router.replace('/post/category/detail/' + this.Data.Slug)
        }
      }).catch((err) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error') + '[' + err.code  + ']',
          text: this.$t('Message.Post/SaveFail-2') + err.msg,
          type: 'error'
        })
      })
    },
    Add() {
      if (this.Data.Slug === '') {
        return Promise.reject({
          code: 403,
          msg: this.$t('Message.Post/SlugEmpty')
        })
      }
      //語系資料同步到預設值
      if (this.$store.state.user.permission.MutipleLocale) {
        this.SynchronizeDefaultLocaleData()
      }
      // 若沒有代稱則使用分類名稱直接帶入代稱
      if (!this.Data.Slug) {
        this.Data.Slug = this.Data.Name + '-' + this.$dayjs().unix()
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/content/category/add',
        method: 'post',
        data: {
          ...this.Data,
          Status: this.PostCategoryStatus ? 1 : 0,
          EnableResponseContent: this.EnableResponseContent ? 1 : 0
        }
      }).then(({data}) => {
        return {
          ...data,
          msg: this.$t('Message.PostCategory/AddSuccess')
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Edit() {
      if (this.Data.Slug === '') {
        return Promise.reject({
          code: 403,
          msg: this.$t('Message.Post/SlugEmpty')
        })
      }
      //語系資料同步到預設值
      if (this.$store.state.user.permission.MutipleLocale) {
        this.SynchronizeDefaultLocaleData()
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/content/category/edit',
        method: 'post',
        data: {
          slug: this.OldSlug,
          updateData: {
            ...this.Data,
            Status: this.PostCategoryStatus ? 1 : 0,
            EnableResponseContent: this.EnableResponseContent ? 1 : 0
          }
        }
      }).then(() => {
        return {
          msg: this.$t('Message.PostCategory/EditSuccess')
        }
      }).catch((err) => {
        throw err
      })
    },
    GetData() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/content/category/detail',
        method: 'get',
        params: {
          slug: this.$route.params.slug
        }
      }).then(({data}) => {
        this.Loading = false
        this.Data = data.detail
        this.OldSlug = this.Data.Slug
        this.PostCategoryStatus = (data.detail.Status === 1)

        //多語系資料處理
        if (this.$store.state.user.permission.MutipleLocale) {
          if (!this.Data.LanguageData) {
            this.$set(this.Data, 'LanguageData', {})
            this.$store.state.user.permission.Locales.forEach((locale) => {
              this.$set(this.Data.LanguageData, locale, {
                Name: '',
                Tags: [],
                SEO: {
                  Description: '',
                  Keywords: ''
                },
                Cover: '',
                Carousel: [],
                ResponseData: {
                  Mobile: {},
                  Web: {}
                }
              })
            })
          }
          this.NowChooseLanguage = this.$store.state.user.permission.DefaultLocale || 'default'
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    SynchronizeDefaultLocaleData() {
      const DefaultLocale = this.$store.state.user.permission.DefaultLocale
      if (DefaultLocale !== 'default') {
        this.Data.Name = this.Data.LanguageData[DefaultLocale].Name
        this.Data.SEO = this.Data.LanguageData[DefaultLocale].SEO
        this.Data.Cover = this.Data.LanguageData[DefaultLocale].Cover
        this.Data.Carousel = this.Data.LanguageData[DefaultLocale].Carousel
        this.Data.ResponseData = this.Data.LanguageData[DefaultLocale].ResponseData
      }
    },
    AddTag (newTag) {
      this.Data.Tags.push(newTag)
      if (this.NowChooseLanguage !== 'default') {
        this.Data.LanguageData[this.NowChooseLanguage].Tags.push(newTag)
      }
    },
    AddVariable() {
      this.VariableList.push({
        Index: '',
        Value: '',
        Type: 'string'
      })
    },
    RemoveVariable(_Index) {
      this.VariableList.splice(_Index, 1)
    },
    OpenMediaStore(type = 'Carousel', multiple = true, index = false) {
      this.ChooseImageType = type
      this.MediaStoreConfig.ChooseImageList = []
      this.MediaStoreConfig.Display = !this.MediaStoreConfig.Display
      this.MediaStoreConfig.Multiple = multiple
      if (index !== false) {
        this.ChooseImageIndex = index
      }
    },
    SetImages (type = 'Carousel') {
      switch (type) {
        case 'Cover':
          this.SetSingleImage('Cover')
          break
        default:
          this.SetMultipleImages(type)
          break
      }
    },
    SetSingleImage(type, index) {
      if (this.$store.state.user.permission.StaticDomain) {
        if (this.NowChooseLanguage === 'default') {
          if (index) this.Data[type][index] = this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList
          else this.$set(this.Data, type, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
        } else {
          if (index) this.Data.LanguageData[this.NowChooseLanguage][type][index] = this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList
          else this.$set(this.Data.LanguageData[this.NowChooseLanguage], type, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
        }
      } else {
        this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
          if (this.NowChooseLanguage === 'default') {
            if (index) this.Data[type][index] = URL
            else this.$set(this.Data, type, URL)
          } else {
            if (index) this.Data.LanguageData[this.NowChooseLanguage][type][index] = URL
            else this.$set(this.Data.LanguageData[this.NowChooseLanguage], type, URL)
          }
        })
      }
    },
    SetMultipleImages(type) {
      const storageInstance = this.$store.state.userProjectApp.storage()
      if (typeof this.Data[type] === 'undefined') {
        this.$set(this.Data, type, [])
      }
      this.ChooseImageType = ''
      switch (typeof this.MediaStoreConfig.ChooseImageList) {
        case 'string':
          if (this.$store.state.user.permission.StaticDomain) {
            this.$set(this.Data[type], this.ChooseImageIndex, {
              URL: this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList,
              Alt: ''
            })
          } else {
            storageInstance.ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
              this.$set(this.Data[type], this.ChooseImageIndex, {URL, Alt: ''})
            })
          }
          break
        case 'object':
          if (Array.isArray(this.MediaStoreConfig.ChooseImageList)) {
            this.MediaStoreConfig.ChooseImageList.forEach(imgPath => {
              if (this.$store.state.user.permission.StaticDomain) {
                this.Data[type].push({
                  URL: this.$store.state.user.permission.StaticDomain + imgPath,
                  Alt: ''
                })
              } else {
                storageInstance.ref().child(imgPath).getDownloadURL().then((URL) => {
                  this.Data[type].push({
                    URL,
                    Alt: ''
                  })
                })
              }
            })
          }
          break
      }
    },
    GetCategories () {
      return this.$store.dispatch('InnerRequest', {
        url: '/content/category/list',
        method: 'get',
      }).then(({data}) => {
        this.CategoryList = data.list.filter(item => item.Slug !== this.$route.params.slug).map((category) => {
          return { label: category.Name, value: category.Slug }
        })
      }).catch((err) => {
        throw err
      })
    },
    SynchronizeCategories() {
      this.Data.RelateCategories = this.SelectCategories.map(data => {
        return data.value
      })
    },
    OpenMediaMeta(type = 'Carousel', index = false) {
      if (index === false) {
        return false
      }
      this.MediaMetaConfig.Display = !this.MediaMetaConfig.Display
      if (this.NowChooseLanguage === 'default') {
        this.MediaMetaConfig.ImageMeta = this.Data[type][index]
      } else {
        this.MediaMetaConfig.ImageMeta = this.Data.LanguageData[this.NowChooseLanguage][type][index]
      }
      this.ChooseImageType = type
      this.ChooseImageIndex = index
    },
    SetMeta() {
      this.MediaMetaConfig.ImageMeta = {}
      this.ChooseImageType = ''
      this.ChooseImageIndex = false
      this.MediaMetaConfig.Display = false
    },
    RemoveImage(type = '', index) {
      if (this.NowChooseLanguage === 'default') {
        this.Data[type].splice(index, 1)
      } else {
        this.Data.LanguageData[this.NowChooseLanguage][type].splice(index, 1)
      }
    }
  }
}
</script>
